<template>
	<div class="notice-index-page">
		<van-list
			v-model="loading"
			:finished="finished"
			:finished-text="translate('no_more_records_yet')"
			@load="loadData"
		>
			<van-row class="item" v-for="(item, index) in lists" :key="index" @click="goInfo(item.id)">
				<van-col span="20" is-link class="title" v-html="item.name"></van-col>
				<van-col span="4" class="status">
					<span style="color: red;" v-if="item.status == 1">{{translate('to_be_processed')}}</span>
					<span style="color: steelblue;" v-if="item.status == 2">{{translate('processed')}}</span>
				</van-col>
				<van-col span="24" class="date" v-text="item.date"></van-col>
			</van-row>
		</van-list>
	</div>
</template>

<script>
	export default {
		name: 'feedback_history',
		data() {
			return {
				loading: false,
				finished: false,
				
				lists: [],
				
				formItem: {
					page: 1,
					limit: 10,
					token: ""
				},
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.loadData()
			}
		},
		methods: {
			loadData() {
				this.loading = true
				
				this.$axios({
					method: 'post',
					url: 'feedback/history', 
					data: this.formItem
				}).then ((res) => {
					
					this.loading = false
					
					if (res.success) {
						if (res.data.length != 0) {
							this.lists = this.lists.concat(res.data)
							
							if (res.data.length < this.formItem.limit) {
								this.finished = true
							} else {
								this.formItem.page ++
							}
						} else {
							this.finished = true
						}
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			goInfo(id) {
				this.$router.push('/feedback/info?id=' + id + '&token=' + this.formItem.token) 
			}
		}
	}
</script>